

































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {AppModule} from '@/store/modules/appState';
import {UsersModule} from '@/store/modules/usersState';

@Component({
  name: 'TopBar',
  components: {},
  data: () => {
    return {
      userName: AppModule.UserName,
    };
  },
})
export default class extends Vue {
  @Prop() private isAdmin!: boolean;
  private avatar = '/img/user.d961d73a.png';

  async created() {
    this.$data.userName = AppModule.UserName;
    await UsersModule.GetCurrentUser();
  }

  private logout(ev: Event) {
    ev.preventDefault();
    AppModule.Logout().then(() => {
      this.$router.push({path: '/login'}).catch(() => {
        //
      });
    });
  }

  get checkAdmin() {
    return AppModule.roles.find((role) => role === 'admin');
  }

  get currentUser() {
    return UsersModule.currentUser;
  }

  private openLink(link: string) {
    this.$router.push({path: link}).catch(() => {
      //
    });
  }
}
