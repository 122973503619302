import AnsiUp from 'ansi_up';

export const decodeASCII = (value: string): string => {
  try {
    return (value = new AnsiUp().ansi_to_html(
      atob(value).replace(/[^\x20-\x7E\s]|\[K/g, '')
    ));
  } catch (e) {
    console.error('Log parsing error:', e);
    return '';
  }
};
