































import { ITerminalLog } from '@/models/users';
import { UserDashboardModule } from '@/store/modules/userDashboardState';
import { LabHistoryModule } from '@/store/modules/labHistoryState';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LabLog from './LabLog.vue';

Vue.filter('formatDate', function(value) {
  if (value) {
    return new Date(String(value)).toLocaleString();
  }
});

@Component({
  name: 'Logs',
  components: {
    LabLog
  }
})
export default class extends Vue {
  @Prop() private labID!: number;
  @Prop() private labName!: string;
  @Prop() private adminLogs?: ITerminalLog[];
  @Prop() private total?: number;
  private selectedLog = null;

  get logs() {
    return LabHistoryModule.LabHistory as ITerminalLog[];
  }

  set logs(logs: ITerminalLog[]) {
    LabHistoryModule.SetHistory(logs);
  }

  mounted() {
    if (!this.adminLogs) {
      UserDashboardModule.GetLabLogsCurrentUser(this.labID, 1).then(() => {
        if (UserDashboardModule.labLogForUser) {
          this.logs = UserDashboardModule.labLogForUser;
        }
      });

      return;
    }

    this.logs = this.adminLogs;
  }

  @Watch('adminLogs')
  adminLogsChanges() {
    this.logs = this.adminLogs as ITerminalLog[];
  }

  selectLog(id: number) {
    if (typeof id === 'number' && !this.logs.find(el => el.id === id)?.log) {
      UserDashboardModule.GetLogDataCurrentUser(
        this.logs.find(el => el.id === id)?.resultID || id
      )
        .then(() => {
          LabHistoryModule.SetHistoryLog(
            UserDashboardModule.labLogDataForUser
              ? {
                  id,
                  log: UserDashboardModule.labLogDataForUser
                }
              : { id, log: '\n The log is empty' }
          );
        })
        .catch(e => console.error('Error:', e));
    }
  }
}
