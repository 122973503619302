


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'ProgressBar',
})
export default class extends Vue {
  @Prop() private percentage!: number;
  private color = '#000';
  private colors: {color: string; percentage: number}[] = [
    {color: '#eeeeee', percentage: 0},
    {color: '#409EFF', percentage: 10},
    {color: '#409EFF', percentage: 90},
    {color: '#67C23A', percentage: 100},
  ];
}
