import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators';
import store from '@/store';
import {
  ICourseInfo,
  ITerminalLog,
  IUserCourseInfo,
  IUserLabInfo
} from '@/models/users';
import { getCourseByID, getCourses, getCoursesForUser } from '@/api/courses';
import { getLabLogsCurrentUser, getLogDataCurrentUser } from '@/api/labs';

@Module({ dynamic: true, store, name: 'userDashboardState' })
class UserDashboard extends VuexModule {
  courses: IUserCourseInfo[] = [];
  coursesForUser: IUserCourseInfo[] = [];
  selectedCourse: IUserCourseInfo | null = null;
  labsForUser: IUserLabInfo | null = null;
  labLogForUser: ITerminalLog[] | null = null;
  labLogDataForUser: string | null = null;
  totalLogs = 0;
  currentCourse: ICourseInfo | null = null;

  @Mutation
  private SET_COURSES(courses: IUserCourseInfo[]) {
    this.courses = courses;
  }

  @Mutation
  private SET_COURSES_FOR_USER(courses: IUserCourseInfo[]) {
    this.coursesForUser = courses;
  }

  @Mutation
  private SET_COURSE(course: IUserCourseInfo | null) {
    this.selectedCourse = course;
  }

  @Mutation
  private SET_LABS_FOR_USER(labs) {
    this.labsForUser = labs;
  }

  @Mutation
  private SET_LAB_LOG_FOR_USER(labLog: ITerminalLog[]) {
    this.labLogForUser = labLog;
  }

  @Mutation
  private SET_LAB_LOG_DATA_FOR_USER(log: string) {
    this.labLogDataForUser = log;
  }

  @Mutation
  private SET_LAB_LOG_PAGES(totalPages: number) {
    this.totalLogs = totalPages * 10;
  }

  @Mutation
  private SET_CURRENT_COURSE(course) {
    this.currentCourse = course;
  }

  @Action
  public async GetCourseByID(courseID) {
    try {
      const response = await getCourseByID({ courseID: courseID });
      this.SET_CURRENT_COURSE(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async GetLabLogsCurrentUser(labID: number, page: number) {
    try {
      const response = await getLabLogsCurrentUser({ labID, page });
      this.SET_LAB_LOG_FOR_USER(response?.data.logs as ITerminalLog[]);
      this.SET_LAB_LOG_PAGES(response?.data.totalPages);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async GetLogDataCurrentUser(logID: number) {
    try {
      const response = await getLogDataCurrentUser({ logID });
      this.SET_LAB_LOG_DATA_FOR_USER(response?.data);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async GetCourses() {
    try {
      const response: any = await getCourses();
      this.SET_COURSES(response.data);
    } catch (e) {
      console.log(e);
    }
  }
  @Action
  public async GetCoursesForUser() {
    try {
      const response: any = await getCoursesForUser();
      this.SET_COURSES_FOR_USER(response.data);
    } catch (e) {
      console.log(e);
    }
  }
  @Action
  public SetSelectedCourse(course: IUserCourseInfo | null) {
    this.SET_COURSE(course);
  }
}

export const UserDashboardModule = getModule(UserDashboard);
