import request from '@/utils/request';
import {IAuthResponse, ILoginForm} from '@/models/auth';
import {AxiosPromise} from 'axios';
import {ICourseInfo, ILabInfo} from '@/models/users';

interface ICourseRequest {
  createdAt: string;
  description: string;
  id: number;
  labs: {
    id: number;
    name: string;
    percentage: number;
  }[];
  name: string;
  numOrder: number;
  picture: string;
  updatedAt: string;
}
export const createCourse = (data: ICourseInfo): AxiosPromise<ICourseInfo> =>
  request({
    url: '/course',
    method: 'post',
    data,
  });

export const updateCourse = (
  data: ICourseInfo
): AxiosPromise<{data: boolean}> =>
  request({
    url: '/course',
    method: 'put',
    data,
  });

export const deleteCourse = (data: {
  courseID: number;
}): AxiosPromise<{courseID: boolean}> =>
  request({
    url: '/course',
    method: 'delete',
    data,
  });

export const getCourses = (): AxiosPromise<ICourseInfo[]> =>
  request({
    url: '/courses',
    method: 'get',
  });

export const updateGroupsForCourse = (
  data: any
): AxiosPromise<{data: boolean}> =>
  request({
    url: '/course/editGroupsForCourse',
    method: 'post',
    data,
  });

export const updateGroupsForLab = (data: any): AxiosPromise<{data: boolean}> =>
  request({
    url: '/lab/editGroupsForLab',
    method: 'post',
    data,
  });

export const getCourseByID = (data: {
  courseID: number;
}): AxiosPromise<{data: boolean}> =>
  request({
    url: '/course/getCourseByID',
    method: 'post',
    data,
  });

export const getCoursesForUser = (/* data: {
  userID: number;
} */): AxiosPromise<{
  data: boolean;
}> =>
  request({
    url: '/courses/getCoursesForUser',
    method: 'get',
    /*  data, */
  });
export const getGroupsForCourse = (data: {
  courseID: number;
}): AxiosPromise<Array<{
  access: boolean;
  expireDate: Date | null;
  permissionDate: Date | null;
  groupID: number;
}>> =>
  request({
    url: '/course/getGroupsForCourse',
    method: 'post',
    data,
  });

export const getLabById = (id): AxiosPromise<ILabInfo> =>
  request({
    url: `/lab/data?labID=${id}`,
    method: 'get',
  });

export const getKubernetesVersions = (): AxiosPromise<any> =>
  request({
    url: '/images/k8s',
    method: 'get',
  });
