import { ILogsHistory } from './../models/users.d';
import { ILabInfo, IChallenge } from '@/models/users';
import request from '@/utils/request';
import { AxiosPromise } from 'axios';

interface ILabRequest {
  courseID: number;
  createdAt: string;
  description: string;
  id: number;
  laboratoryToken: string;
  name: string;
  numOrder: number;
  //totalTime: string;
  updatedAt: string;
  videoURL: string;
}

export const getLabs = (): AxiosPromise<ILabRequest> =>
  request({
    url: '/lab',
    method: 'get'
  });
export const updateLab = (
  data: ILabInfo | null
): AxiosPromise<ILabInfo | null> =>
  request({
    url: '/lab',
    method: 'put',
    data
  });
export const createLab = (data: ILabInfo): AxiosPromise<ILabInfo> =>
  request({
    url: '/lab',
    method: 'post',
    data
  });
export const deleteLab = (data: {
  labID: number;
}): AxiosPromise<{ data: boolean }> =>
  request({
    url: '/lab',
    method: 'delete',
    data
  });
export const getLabData = (labID): AxiosPromise<ILabRequest> =>
  request({
    url: `/lab/data?labID=${labID}`,
    method: 'get'
  });
export const getLabStateById = (id): AxiosPromise<ILabInfo> => {
  return request({
    url: `/lab/state?labID=${id}`,
    method: 'get'
  });
};
export const getGroupsForLab = (data: {
  labID: number;
}): AxiosPromise<Array<{
  access: boolean;
  expireDate: Date | null;
  permissionDate: Date | null;
  groupID: number;
}>> =>
  request({
    url: '/lab/getGroupsForLab',
    method: 'post',
    data
  });

export const getLabLogsCurrentUser = (data: {
  labID: number;
  page: number;
}): AxiosPromise<ILogsHistory> =>
  request({
    url: '/lab/getLabLogsCurrentUser',
    method: 'post',
    data
  });

export const getLogDataCurrentUser = (data: {
  labID?: number;
  logID?: number;
}): AxiosPromise<string> =>
  request({
    url: '/lab/getLogDataCurrentUser',
    method: 'post',
    data
  });

export const getLabLogScripts = (data: {
  labID: number;
  page: number;
  userID?: number;
}): AxiosPromise<ILogsHistory> =>
  request({
    url: '/lab/getLabLogScripts',
    method: 'post',
    data
  });

export const getLabLogScriptData = (data: {
  logID: number;
}): AxiosPromise<string> =>
  request({
    url: '/lab/getLabLogScriptData',
    method: 'post',
    data
  });

//challenges

export const getChallengeByID = data =>
  request({
    url: `/challenge?labID=${data}`,
    method: 'get',
    data
  });

export const getChallenges = () =>
  request({
    url: '/challenge/execEnvs',
    method: 'get'
  });

export const updateChallenge = (data: IChallenge) =>
  request({
    url: '/challenge',
    method: 'put',
    data
  });

export const createChallenge = (data: IChallenge) =>
  request({
    url: '/challenge',
    method: 'post',
    data
  });
export const deleteChallenge = (data: IChallenge['labID']) =>
  request({
    url: '/challenge',
    method: 'delete',
    data
  });

export const getLabLogScriptResult = data =>
  request({
    url: '/lab/getLabLogScriptResult',
    method: 'post',
    data
  });
