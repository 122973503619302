






import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Terminal } from 'xterm-js';
import { FitAddon, WebLinksAddon } from '@/addons';

@Component({
  name: 'LabLog',
})
export default class extends Vue {
  @Prop() private log!: string;
  @Ref('terminal') readonly terminalRef!: HTMLDivElement;
  private resizeObserver = new ResizeObserver((event) => {
    const cr = event[0]?.contentRect;
    cr && this.onResize(cr.width, cr.height);
  });
  private term: Terminal = new Terminal({
    disableStdin: true,
    cursorStyle: 'underline',
    // logLevel: 'debug',
    rows: 2,
  });
  private fitAddon: FitAddon = new FitAddon();
  private webLinksAddon: WebLinksAddon = new WebLinksAddon();

  created() {
    this.term.loadAddon(this.fitAddon);
    this.term.loadAddon(this.webLinksAddon);
    this.term.attachCustomKeyEventHandler(() => false);
  }

  mounted() {
    if (this.log) {
      this.drawLog(this.log)
    }
  }

  @Watch('log')
  watchLog(){
    this.drawLog(this.log);
  }

  drawLog(log: string) {
    const text = this.getNormalMode(log).replaceAll('\n\n', '\n') || log;

    this.$nextTick(() => {
      setTimeout(() => {
        this.term.write(text);
        this.term.open(this.terminalRef);
        this.term.resize(this.term.cols, ++text.split('\n').length);
        this.resizeObserver.observe(this.terminalRef);
        this.fitAddon.fit();
      }, 50);
    });
  }

  beforeDestroy() {
    this.resizeObserver.disconnect();
  }

  destroyed() {
    this.term.clear();
    this.term.reset();
    this.term.dispose();
  }

  onResize(w, h) {
    const dim = this.fitAddon.proposeRealDimensions(w, h);
    if (dim && dim.rows > 1 && +dim.rows > +this.term.rows) {
      this.$nextTick(() => {
        this.term.resize(dim.cols, dim.rows);
      });
    }
  }

  private getNormalMode(str) {
    if (!str) {
      return '';
    }
    try {
      const out = atob(str);
      return out;
    } catch (e) {
      return '';
    }
  }
}
