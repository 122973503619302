import { IGroup, IUser, ILabInfo, ICourseInfo } from '@/models/users';
import { Difficulty } from './users.enums';

export const BLANK_USER: () => IUser = () => ({
  id: 0,
  userName: '',
  groupID: null,
  email: '',
  password: '',
  isAdmin: false,
  updatedAt: '',
  createdAt: ''
});

export const BLANK_GROUP: () => IGroup = () => ({
  id: 0,
  description: '',
  name: '',
  createdAt: '',
  updatedAt: '',
  checked: false
});

export const BLANK_COURSE: () => ICourseInfo = () => ({
  id: 0,
  name: '',
  description: '',
  labs: [],
  accessGroups: [],
  image: ''
});

export const BLANK_LAB: () => ILabInfo = () => ({
  name: '',
  id: 0,
  courseID: null,
  videoUrl: '',
  totalTime: '',
  difficulty: Difficulty.EASY,
  laboratoryToken: '',
  description: '',
  accessGroups: [],
  progress: 0,
  percentage: 0,
  numOrder: 0,
  challengeID: 0
});
