import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators';
import store from '@/store';
import { ICourse, IEditUser, IUser, IStat, ITerminalLog } from '@/models/users';
//import { courses } from '@/fakeData/dashboard';
import { dynamicSort } from '@/utils/filters';
import {
  createUser,
  deleteUser,
  editUser,
  getUsers,
  getUserLabs,
  getUserStats
} from '@/api/users';
import { getCourses } from '@/api/courses';
interface ILabRequest {
  id: number;
  command: string;
  result: string;
  date: string;
}
import { getCurrentUser } from '@/api/users';
import { MessageBox } from 'element-ui';

[];
@Module({ dynamic: true, store, name: 'users' })
class Users extends VuexModule {
  public users: IUser[] = [];
  public courses: ICourse[] = [];
  public editedUser: IUser | null = null;
  public showEdit = false;
  public showCreate = false;
  public stats: IStat[] = [];
  public logs: ITerminalLog[] = [];
  public totalLogs = 0;
  public currentUser: any = {};

  @Mutation
  private SET_USERS(users: IUser[]) {
    this.users = users;
  }

  @Mutation
  private EDIT_USER(payload: { index: number; user: IUser }) {
    this.users[payload.index] = payload.user;
  }

  @Mutation
  private DELETE_USER(payload: { index: number; user: IUser }) {
    this.users.splice(payload.index, 1);
  }

  @Mutation
  private CREATE_USER(user: IUser) {
    this.users.push(user);
  }

  @Mutation
  private SET_COURSES(courses: ICourse[]) {
    this.courses = courses;
  }

  @Mutation
  private SET_EDITED_USER(user: IUser | null) {
    this.editedUser = user;
  }

  @Mutation
  private GET_STATS(stats: IStat[]) {
    this.stats = stats;
  }

  @Mutation
  private SET_LAB_LOGS(logs: ITerminalLog[]) {
    this.logs = logs;
  }

  @Mutation
  private SET_TOTAL_PAGES(totalPages: number) {
    this.totalLogs = totalPages * 10;
  }

  @Mutation
  private UPDATE_CUR_USER(user: any) {
    this.currentUser = Object.assign({}, user);
  }

  @Action
  public async GetUsers() {
    try {
      const response = await getUsers();
      if (!response.data) {
        response.data = [];
      }
      const users = response.data;
      this.SET_USERS([...users]);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public sortUsers(data: { prop: string; order: string | null }) {
    let order = 1;
    if (data.order === null) {
      this.users.sort(dynamicSort('id', 1));
    } else {
      order = data.order === 'descending' ? -1 : 1;
      this.users.sort(dynamicSort(data.prop, order));
    }
  }

  @Action
  public async GetCourses() {
    try {
      const response: any = await getCourses();
      this.SET_COURSES(response.data);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public SetEditedUser(user: IUser) {
    this.SET_EDITED_USER(user);
  }

  @Action
  public async EditUser(user: IEditUser) {
    let uName;

    if (user.userName) {
      uName = user.userName
        .split(' ')
        .filter(Boolean)
        .map(el => el[0].toUpperCase() + el.slice(1, el.length))
        .join(' ');
    }

    user = {
      ...user,
      userName: uName
    };

    try {
      const response = await editUser(user);
      if (response?.data === user.id) {
        this.EDIT_USER({
          index: this.users.findIndex((u: IEditUser) => u.id === user.id),
          user: user
        });
        this.UPDATE_CUR_USER(user);

        return await MessageBox.alert(
          'Profile updated successfully',
          'Success',
          {
            type: 'success'
          }
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async DeleteUser(user: IUser) {
    const userIndex = this.users.findIndex((u: IUser) => u.id === user.id);
    try {
      const response = await deleteUser({ userID: user.id });
      if (response.data) {
        this.DELETE_USER({ index: userIndex, user: user });
      }
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async CreateUser(user: IUser) {
    if (user.userName) {
      user.userName = user.userName
        .split(' ')
        .filter(Boolean)
        .map(el => el[0].toUpperCase() + el.slice(1, el.length))
        .join(' ');
    }

    try {
      const response = await createUser(user);
      if (response && response.data) {
        this.CREATE_USER(response.data);
        return true;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @Action
  public async GetStats(userID: number) {
    try {
      const response = await getUserStats({ userID });
      const stat: IStat[] = response.data;
      this.GET_STATS(stat);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async getUserLabsLog(payload) {
    try {
      const response = await getUserLabs(payload);
      this.SET_LAB_LOGS(response?.data.logs as ITerminalLog[]);
      this.SET_TOTAL_PAGES(response?.data.totalPages);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({})
  public async GetCurrentUser() {
    const { data } = await getCurrentUser();
    this.UPDATE_CUR_USER(data);
    return data;
  }
}

export const UsersModule = getModule(Users);
