import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { IScriptLog, ITerminalLog } from '@/models/users';

@Module({ dynamic: true, store, name: 'historyLab' })
class LabHistoryState extends VuexModule {
  LabHistory: IScriptLog[] | ITerminalLog[] = [];

  @Mutation
  private SET_HISTORY(logs: IScriptLog[] | ITerminalLog[]) {
    this.LabHistory = logs;
  }

  @Mutation
  private SET_HISTORY_LOG(data: { id: number; log: string }) {
    this.LabHistory[this.LabHistory.findIndex(el => el.id === data.id)].log = data.log;
    this.LabHistory = [...this.LabHistory] as IScriptLog[] | ITerminalLog[];
  }

  @Action({ rawError: true })
  public SetHistory(logs) {
    this.SET_HISTORY(logs);
  }

  @Action({ rawError: true })
  public SetHistoryLog(data: { id: number; log: string }) {
    this.SET_HISTORY_LOG(data);
  }
}

export const LabHistoryModule = getModule(LabHistoryState);
