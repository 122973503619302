import Vue from 'vue';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { BLANK_USER, BLANK_GROUP, BLANK_COURSE, BLANK_LAB } from '@/models'
import { IUser, IGroup, ICourseInfo, ILabInfo } from '@/models/users';

@Module({ dynamic: true, store, name: 'dialogs' })
class Dialog extends VuexModule {
  showEdit = false;
  showCreate = false;
  editing = false;

  showNewUserAdded = false;
  newUserModel = BLANK_USER();

  showNewGroupAdded = false;
  newGroupModel = BLANK_GROUP();

  showNewCourseAdded = false;
  newCourseModel = BLANK_COURSE();

  showNewLabAdded = false;
  newLabModel = BLANK_LAB();

  @Mutation
  private TOGGLE_EDIT() {
    this.showEdit = !this.showEdit;
    this.showCreate = false;
  }

  @Mutation
  private TOGGLE_CREATE() {
    this.showCreate = !this.showCreate;
    this.showEdit = false;
  }

  @Mutation
  private CLOSE_CREATE() {
    this.showCreate = false;
    this.showEdit = false;
  }

  @Mutation
  private SET_EDITING(editing: boolean) {
    this.editing = editing;
  }

  @Mutation
  private SET_NEW_USER_MODEL(user: IUser) {
    this.newUserModel = { ...user };
  }

  @Mutation
  private SET_SHOW_NEW_USER_ADDED(payload: boolean) {
    this.showNewUserAdded = payload;
  }

  @Mutation
  private SET_NEW_GROUP_MODEL(group: IGroup) {
    this.newGroupModel = { ...group };
  }

  @Mutation
  private SET_SHOW_NEW_GROUP_ADDED(payload: boolean) {
    this.showNewGroupAdded = payload;
  }

  @Mutation
  private SET_SHOW_NEW_COURSE_ADDED(payload: boolean) {
    this.showNewCourseAdded = payload;
  }

  @Mutation
  private SET_NEW_COURSE_MODEL(course: ICourseInfo) {
    this.newCourseModel = { ...course };
  }

  @Mutation
  private SET_NEW_LAB_MODEL(lab: ILabInfo) {
    this.newLabModel = { ...lab };
  }

  @Mutation
  private SET_SHOW_NEW_LAB_ADDED(payload: boolean) {
    this.showNewLabAdded = payload;
  }

  @Action
  public async toggleEdit() {
    this.TOGGLE_EDIT();
    this.showEdit ? this.SET_EDITING(true) : this.SET_EDITING(false);
  }

  @Action
  public async toggleCreate() {
    this.TOGGLE_CREATE();
    this.showEdit ? this.SET_EDITING(true) : this.SET_EDITING(false);
  }

  @Action
  public async closeCreate() {
    try {
      this.CLOSE_CREATE();
      this.showEdit ? this.SET_EDITING(true) : this.SET_EDITING(false);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async toggleNewUserAdded(user?: IUser) {
    if (user) {
      this.SET_NEW_USER_MODEL(user);
      await Vue.nextTick();
      this.SET_SHOW_NEW_USER_ADDED(true);
    } else {
      this.SET_SHOW_NEW_USER_ADDED(false);
    }
  }

  @Action
  public async toggleNewGroupAdded(group?: IGroup) {
    if (group) {
      this.SET_NEW_GROUP_MODEL(group);
      await Vue.nextTick();
      this.SET_SHOW_NEW_GROUP_ADDED(true);
    } else {
      this.SET_SHOW_NEW_GROUP_ADDED(false);
    }
  }

  @Action
  public async toggleNewCourseAdded(course?: ICourseInfo) {
    if (course) {
      this.SET_NEW_COURSE_MODEL(course);
      await Vue.nextTick();
      this.SET_SHOW_NEW_COURSE_ADDED(true);
    } else {
      this.SET_SHOW_NEW_COURSE_ADDED(false);
    }
  }

  @Action
  public async toggleNewLabAdded(lab?: ILabInfo) {
    if (lab) {
      this.SET_NEW_LAB_MODEL(lab);
      await Vue.nextTick();
      this.SET_SHOW_NEW_LAB_ADDED(true);
    } else {
      this.SET_SHOW_NEW_LAB_ADDED(false);
    }
  }
}

export const DialogModule = getModule(Dialog);
