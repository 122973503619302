export enum Difficulty {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard'
}

export enum ExecType {
  CONTAINER = 0,
  EC2 = 1,
  KUBERNETES = 2
}
