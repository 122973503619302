import request from '@/utils/request';
import { AxiosPromise } from 'axios';
import {
  IDashboardUser,
  ILogsHistory,
  IStat,
  IUser,
  IEditUser
} from '@/models/users';

interface IUserRequest {
  createdAt: string;
  email: string;
  groupId: number;
  id: number;
  isAdmin: boolean;
  updatedAt: string;
  userName: string;
}

interface ILabRequest {
  command: string;
  date: string;
  id: number;
  result: string;
}

export const getDashboardData = (): AxiosPromise<IDashboardUser[]> =>
  request({
    url: '/getDashboardInfo',
    method: 'get'
  });
export const getUsers = (): AxiosPromise<IUser[]> =>
  request({
    url: '/users',
    method: 'get'
  });
export const getDashboardLabsInfo = (data: {
  courseID: number;
}): AxiosPromise<IUser[]> =>
  request({
    url: '/getDashboardLabsInfo',
    method: 'post',
    data
  });
export const createUser = (data: IUser): AxiosPromise<IUser> =>
  request({
    url: '/user',
    method: 'post',
    data
  });
export const deleteUser = (data: {
  userID: number;
}): AxiosPromise<{ data: boolean }> =>
  request({
    url: '/user',
    method: 'delete',
    data
  });
export const editUser = (data: IEditUser): AxiosPromise<number> =>
  request({
    url: '/user',
    method: 'put',
    data
  });
export const getCurrentUser = (): AxiosPromise<IUser> =>
  request({
    url: '/user/getUser',
    method: 'get'
  });
export const getUserStats = (data: { userID: number }): AxiosPromise<IStat[]> =>
  request({
    url: '/user/getUserInfo',
    method: 'post',
    data
  });
export const getUserLabs = (data: {
  userID: number;
  labID: number;
  page: number;
}): AxiosPromise<ILogsHistory> =>
  request({
    url: '/user/getUserLabLogs',
    method: 'post',
    data
  });
